<template>
  <div>
    <v-row class="mt-5 pl-3 pr-7" justify="space-between" v-if="showHeaderPage">
      <div>
        <p class="title">Informações de Endereço</p>
      </div>
      <div v-if="showEditData">
        <v-btn class="text--primary" style="background: #d0d2d5" v-if="isDisabledFields" large @click="onClickEdit()">
          <v-icon class="mr-2">
            fas fa-edit
          </v-icon>
          Editar
        </v-btn>
        <v-btn class="text--white" :disabled="awaitRequest" color="primary" v-else large @click="onClickSaveSessionStorage()">
          <v-icon class="mr-2" @click="onClickSaveSessionStorage()">
            fas fa-save
          </v-icon>
          Salvar
        </v-btn>
      </div>
    </v-row>
    <v-row class="mt-5 pr-4">
      <v-col cols="12" md="3">
        <v-text-field
          label="CEP"
          placeholder="Informe o CEP"
          v-mask="'#####-###'"
          v-model="address.code"
          outlined
          color="textPrimary"
          @change="fetchAddressByZipCodeAndSetFields(); $emit('updateAddressData', address)"
          :disabled="isDisabledFields"
          :error-messages="criticizedFields && criticizedFields.length > 0 && criticizedFields.includes('address_zip') ? 'Campo criticado' : ''"
          @input="observerAddressData()"
          :class="verifyFieldInArray('addressZip') ? 'alterationColorText alterationColorBorder' : ''"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Endereço"
          placeholder="Informe o endereço"
          v-model="address.log"
          outlined
          color="textPrimary"
          :disabled="isDisabledFields"
          :error-messages="criticizedFields && criticizedFields.length > 0 && criticizedFields.includes('address') ? 'Campo criticado' : ''"
          @change="$emit('updateAddressData', address)"
          @blur="address.log = formatter.formatToTitleCase(address.log)"
          @input="observerAddressData()"
          :class="verifyFieldInArray('address') ? 'alterationColorText alterationColorBorder' : ''"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="address.number"
          @keydown="$event.key === '-' ? $event.preventDefault() : null"
          @blur="address.number ? rule.verifyNegative(address.number, 'fieldCheckedAddressNumber') : ''"
          @focus="verifyMessageAddressNumber = rule.isFocusVerifyNegative(true, address.number)"
          @change="verifyMessageAddressNumber = rule.isFocusVerifyNegative(false, address.number); $emit('updateAddressData', address)"
          id="fieldCheckedAddressNumber"
          v-mask="'#########'"
          label="Número"
          placeholder="Informe apenas números"
          color="textPrimary"
          outlined
          :rules="address.number ? [rule.validateNegativeDecimal(address.number, 'int')] : []"
          :disabled="isDisabledFields"
          :error-messages="criticizedFields && criticizedFields.length > 0 && criticizedFields.includes('address_number') ? 'Campo criticado' : verifyMessageAddressNumber ? '*Preencha corretamente' : ''"
          @input="observerAddressData()"
          :class="verifyFieldInArray('addressNumber') ? 'alterationColorText alterationColorBorder' : ''"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Complemento"
          placeholder="Informe o complemento"
          v-model="address.complement"
          color="textPrimary"
          outlined
          :disabled="isDisabledFields"
          :error-messages="criticizedFields && criticizedFields.length > 0 && criticizedFields.includes('address_complement') ? 'Campo criticado' : ''"
          @change="$emit('updateAddressData', address)"
          @blur="address.complement = formatter.formatToTitleCase(address.complement)"
          @input="observerAddressData()"
          :class="verifyFieldInArray('addressComplement') ? 'alterationColorText alterationColorBorder' : ''"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Bairro"
          placeholder="Informe o bairro"
          v-model="address.neighborhood"
          color="textPrimary"
          outlined
          :rules="address.neighborhood ? [rule.validateNotSpecialCharacters] : []"
          validate-on-blur
          :disabled="isDisabledFields"
          :error-messages="criticizedFields && criticizedFields.length > 0 && criticizedFields.includes('address_neighborhood') ? 'Campo criticado' : ''"
          @change="$emit('updateAddressData', address)"
          @blur="address.neighborhood = formatter.formatToTitleCase(address.neighborhood)"
          @input="observerAddressData()"
          :class="verifyFieldInArray('addressNeighborhood') ? 'alterationColorText alterationColorBorder' : ''"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Município"
          placeholder="Informe o município"
          v-model="address.city"
          outlined
          color="textPrimary"
          :rules="address.city ? [rule.validateNotSpecialCharacters] : []"
          validate-on-blur
          :disabled="isDisabledFields"
          :error-messages="criticizedFields && criticizedFields.length > 0 && criticizedFields.includes('address_city') ? 'Campo criticado' : ''"
          @change="$emit('updateAddressData', address)"
          @blur="address.city = formatter.formatToTitleCase(address.city)"
          @input="observerAddressData()"
          :class="verifyFieldInArray('addressCity') ? 'alterationColorText alterationColorBorder' : ''"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="address.region"
          :items="states"
          item-text="label"
          item-value="label"
          outlined
          hide-details
          label="UF"
          placeholder="UF"
          color="textPrimary"
          item-color="textPrimary"
          append-icon="fas fa-chevron-down"
          :disabled="isDisabledFields"
          :error-messages="criticizedFields && criticizedFields.length > 0 && criticizedFields.includes('address_state') ? 'Campo criticado' : ''"
          @change="$emit('updateAddressData', address)"
          @input="observerAddressData()"
          :class="verifyFieldInArray('addressState') ? 'alterationColorText alterationColorBorder' : ''"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          :items="logTypes"
          v-model="address.logType"
          label="Tipo de Logradouro"
          placeholder="Escolha o tipo de logradouro"
          item-text="label"
          item-value="value"
          outlined
          hide-details
          color="textPrimary"
          item-color="textPrimary"
          append-icon="fas fa-chevron-down"
          :disabled="isDisabledFields"
          :error-messages="criticizedFields && criticizedFields.length > 0 && criticizedFields.includes('address_type') ? 'Campo criticado' : ''"
          @change="$emit('updateAddressData', address)"
          @input="observerAddressData()"
          :class="verifyFieldInArray('addressType') ? 'alterationColorText alterationColorBorder' : ''"
        />
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Rules from '@/shared/validators/formRules';
import Formatters from '@/shared/formatters/formatters';
import ConstantsMixin from '@/shared/mixins/constants/constantsMixin';
import ViaCepService from '@/services-http/viaCep/ViaCepService';
import ChangedFieldsMixin from '@/shared/mixins/highlightedChangedFields/changedFieldsMixin';

export default {
  name: 'AddressInformationComponent',
  data: () => ({
    verifyMessageAddressNumber: false,
    showEditData: false,
    isDisabledFields: false,
    awaitRequest: false,

    address: {
      code: null,
      log: null,
      city: null,
      region: null,
      logType: null,
      country: 'Brasil',
      neighborhood: null,
      number: null,
      complement: null,
    },
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
  }),

  props: {
    addressData: { type: Object },
    changedFields: { type: Array },
    criticizedFields: {
      type: Array,
      required: false,
    },
    showHeaderPage: {
      type: Boolean,
      required: true,
    },
    isFreeMovement: {
      type: Boolean,
      required: true,
    },
    isDetail: {
      type: Boolean,
      required: true,
    },
  },

  watch: {
    addressData: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.loadAddress(newValue);
        }
      },
    },
  },

  mixins: [
    ConstantsMixin,
    ChangedFieldsMixin,
  ],

  mounted() {
    if (this.$route.name === 'InsuredDetail') {
      this.isDisabledFields = true;
    } else if (this.$route.name === 'Alteration') {
      this.isDisabledFields = true;
    } else {
      this.isDisabledFields = false;
    }
  },

  methods: {
    verifyRouter() {
      if (this.$route.name === 'Alteration' || this.$route.name === 'CriticizedMovesHolder' || this.$route.name === 'EditMovesHolder') {
        this.showEditData = true;
      }
    },
    loadAddress(activeAddress) {
      const {
        addressZip, code, address, log, addressCity, city, addressState, region, addressNeighborhood, neighborhood, addressComplement, complement, addressType, logType, addressNumber, number, addressCountry, country,
      } = activeAddress;

      this.address.code = addressZip ? this.formatZipCode(addressZip) : code ? this.formatZipCode(code) : null;
      this.address.log = address ? this.formatter.formatToTitleCase(address) : log ? this.formatter.formatToTitleCase(log) : null;
      this.address.city = addressCity ? this.formatter.formatToTitleCase(addressCity) : city ? this.formatter.formatToTitleCase(city) : null;
      this.address.region = addressState || region || null;
      this.address.neighborhood = addressNeighborhood ? this.formatter.formatToTitleCase(addressNeighborhood) : neighborhood ? this.formatter.formatToTitleCase(neighborhood) : null;
      this.address.complement = addressComplement ? this.formatter.formatToTitleCase(addressComplement) : complement ? this.formatter.formatToTitleCase(complement) : null;
      this.address.logType = addressType ? addressType.toUpperCase() : logType ? logType.toUpperCase() : null;
      this.address.number = addressNumber || number || null;
      this.address.country = addressCountry || country || null;

      this.observerAddressData();
      setTimeout(() => {
        this.onClickSaveSessionStorage();
      }, 250);
    },
    async fetchAddressByZipCodeAndSetFields() {
      this.clearAddress();
      if (this.address.code && this.address.code.length === 9) {
        this.awaitRequest = true;
        await this.viaCepService.findAddressByZipCode(this.address.code).then((response) => {
          if (response && response.data) {
            this.address.log = response.data.logradouro ? this.formatter.formatToTitleCase(response.data.logradouro) : null;
            this.address.city = response.data.localidade ? this.formatter.formatToTitleCase(response.data.localidade) : null;
            this.address.region = response.data.uf ? response.data.uf : null;
            this.address.neighborhood = response.data.bairro ? this.formatter.formatToTitleCase(response.data.bairro) : null;
            this.address.logType = this.findLogType(this.address.log);
          }
          this.awaitRequest = false;
        }).catch(() => {
          this.awaitRequest = false;
          this.customizeSnackbarMessage('error', 'Não foi possível validar o CEP informado. Por favor, verifique se o CEP informado está correto e continue com o preenchimento do formulário.');
        });
      }
    },
    formatZipCode(zipCode) {
      if (zipCode.length === 8) {
        const part1 = zipCode.substring(0, 5);
        const part2 = zipCode.substring(5);
        return `${part1}-${part2}`;
      }
      return zipCode;
    },
    findLogType(log) {
      if (log) {
        const logSplited = log.split(' ');
        const logTypeFound = logSplited.find((element) => this.logTypes.some((logType) => logType.value === element.toUpperCase()));
        return logTypeFound.toUpperCase();
      }

      return null;
    },
    onClickEdit() {
      this.isDisabledFields = false;
      this.$emit('disabledAddressInformation', this.isDisabledFields);
    },
    onClickSaveSessionStorage() {
      if (this.$route.name !== 'AddressBankInformation') {
        const {
          code, log, city, region, logType, neighborhood, number, complement,
        } = this.address;

        const formatCode = code ? code.replace('-', '') : null;
        this.address.code = formatCode;
        this.address.country = !code && !log && !city && !region && !logType && !neighborhood && !number && !complement ? null : 'Brasil';
        if (city === '') this.address.city = null;
        if (neighborhood === '') this.address.neighborhood = null;
        if (log === '') this.address.log = null;

        sessionStorage.setItem('beneficiaryAddressUpdated', JSON.stringify(this.address));
        this.address.code = formatCode ? this.formatZipCode(formatCode) : null;
        if (this.$route.name === 'Alteration') {
          this.isDisabledFields = true;
        }
        this.$emit('disabledAddressInformation', this.isDisabledFields);
      }
    },
    clearAddress() {
      this.address.logType = null;
      this.address.number = null;
      this.address.complement = null;
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    observerAddressData() {
      this.$emit('observerAddressData', this.address);
    },
  },

  beforeCreate() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.viaCepService = new ViaCepService();
  },

  async created() {
    this.verifyRouter();
  },
};
</script>
